import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import Button from "../components/button/button";
import InnerWrapper from "../components/inner-wrapper/inner-wrapper";
import {
  mainBlack,
  mainWhite,
  screen,
} from "../components/variables/variables";

const Wrapper = styled.div`
  background-color: ${mainBlack};
  text-align: center;
  color: ${mainWhite};
  position: relative;
  min-height: 80vh;
  padding: 180px 0 90px 0;
  @media ${screen.xsmall} {
    padding: 180px 0 90px 0;
  }

  .txt-wrap {
    position: relative;
    z-index: 2;
  }

  .heading {
    font-weight: 300;
    max-width: 510px;
    margin: 0 auto 17px auto;
    line-height: 1.33;
    @media ${screen.xsmall} {
      margin: 0 auto;
    }
  }

  .subheading {
    font-size: 20vw;
    font-weight: 700;
    margin: 0 0 20px 0;
    background: -webkit-linear-gradient(#3a443e, #30794f, #2a9d5a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .btn {
    margin: 0 auto;
  }

  &::after {
    content: "";
    background-image: linear-gradient(to bottom, ${mainBlack}, #2c9658);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    max-height: 530px;
    z-index: 1;
    pointer-events: none;
    @media ${screen.xsmall} {
      height: calc(100px + 30vw);
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="Page Not found" />
      <Wrapper>
        <InnerWrapper>
          <div className="txt-wrap">
            <h1 className="heading">
              We can't find the page that you're looking for{" :("}
            </h1>
            <h2 className="subheading">404</h2>
            <Button className="btn" to="/">
              BACK TO HOME
            </Button>
          </div>
        </InnerWrapper>
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;
